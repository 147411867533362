import React from 'react';
import { axios } from "../../utilities/axios";
import { connect } from 'react-redux';
import AuthScene from "./AuthScene";
import { Link } from "react-router-dom";

const ResetPasswordScene = () => {
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    setError(false);
    setLoading(true);
    const prefix = "";

    axios.post(`${prefix}/api/business/v1/authentication/reset-password`, {email})
      .then(res => {
        setLoading(false);
        setMessage("If the given email address is registered, you will receive an email with instructions on how to reset your password.");
      })
      .catch(err => {
        setLoading(false);
        setError('Something went wrong');
      });
  };

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <AuthScene title={"Forgotten Password"}>
      {error ? <p className="error">{error}</p> : null}

      <p className={"text-center"}>
        Enter the email address you use to log in.
        <br/>
        If an account exists with that email address, you will receive an email with a link to reset your password.
      </p>

      <form onSubmit={onSubmit} className={loading ? 'loading' : ''}>
        <input name="email" type="email" placeholder="Email Address" value={email} onChange={onChange}/>
        {message ? <p className="message">{message}</p> : null}
        <button className={loading ? 'loading' : ''} onClick={onSubmit}>{loading ? 'Loading...' : 'Reset Password'}</button>
        <br/>
        <Link to="/">Back to Login</Link>
      </form>
    </AuthScene>
  );
};

const mapStateToProps = store => {
  return {
    // loadingFromCookies: store.auth.loadingFromCookies,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // setUser: payload => dispatch({type: 'AUTH_SET_USER', payload}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordScene);
  
