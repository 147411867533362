import React, { useState } from 'react';
import AllergensPopup from './components/AllergensPopup';
import './Item.scss';
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function Item(props) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const {item, branches, menu} = props;
  const {visible} = item;

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const onNameChange = (i) => {
    props.update({
      uuid: item.uuid,
      name: i.target.value,
    });
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      props.delete(props.item.uuid);
    }
  };

  const onHiddenBranchesUpdate = (branches) => {
    props.update({
      uuid: item.uuid,
      hidden_branches: branches,
    });
  };

  return (
    <div className="Item">
      <div className="header">
        <div className="wrapper">
          <input type="text" value={item.name || ""} placeholder="Item Name" onChange={onNameChange}/>
        </div>

        <div className="btn-group btn-group-sm">
          <button className={['btn px-3', visible ? 'btn-outline-secondary' : 'btn-outline-danger'].join(' ')} onClick={() => props.update({uuid: item.uuid, visible: !visible})}>
            <FontAwesomeIcon icon={visible ? faEye : faEyeSlash}/>
          </button>
          <button className={'btn btn-outline-secondary px-3'} onClick={togglePopover}>
            <FontAwesomeIcon icon={faChevronRight}/>
          </button>
        </div>

        <Modal
          show={popoverOpen}
          onHide={togglePopover}
          dialogClassName="modal-extra-wide"
        >
          <Modal.Body>
            <AllergensPopup
              toggle={togglePopover}
              item={item}
              menu={menu}
              saveMenu={props.saveMenu}
              restoreMenu={props.restoreMenu}
              restoreMenuItem={props.restoreMenuItem}
              branches={branches}
              deleteItem={onDelete}
              update={props.update}
              onHiddenBranchesUpdate={onHiddenBranchesUpdate}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}