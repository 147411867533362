import React from 'react';
import { createMenuItem, deleteMenuCategory, deleteMenuItem, reorderMenuCategory, updateMenuCategory, updateMenuItem } from '../../../../store/menus';
import { connect } from 'react-redux';
import Item from '../Item';
import './Category.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const Category = (props) => {
  const renderUpper = () => {
    const {uuid, name, sorting_order, visible} = props.category;

    return <div className="upper">
      <input type="text" value={name} placeholder="Category Name" onChange={onNameChange}/>

      <div className="d-flex gap-2 align-items-center justify-content-evenly">
        <div className="btn-group btn-group-sm">
          <button className={'btn btn-outline-secondary px-3'} onClick={() => props.reorder({uuid, sorting_order: sorting_order - 1})}>
            <FontAwesomeIcon icon={faArrowUp} alt="Move Category Up"/>
          </button>
          <button className={'btn btn-outline-secondary px-3'} onClick={() => props.reorder({uuid, sorting_order: sorting_order + 1})}>
            <FontAwesomeIcon icon={faArrowDown} alt="Move Category Down"/>
          </button>
        </div>

        <div className="private btn-group btn-group-sm me-2">
          <button
            onClick={onTogglePrivacy}
            className={`btn ${visible ? 'btn-outline-secondary' : 'btn-primary'}`}
          >
            Private
          </button>

          <button
            onClick={onTogglePrivacy}
            className={`btn ${visible ? 'btn-primary' : 'btn-outline-secondary'}`}
          >
            Public
          </button>
        </div>

        <div className="btn-group btn-group-sm">
          <button className="btn btn-primary" onClick={onAddItem}>Add Item</button>

          <button title="Delete Category" className="btn btn-outline-danger px-4" onClick={onDelete}>
            <FontAwesomeIcon icon={faTrashAlt} size={"lg"}/>
          </button>
        </div>
      </div>
    </div>;
  };

  const onAddItem = () => {
    props.addItem(props.category.uuid);
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want to delete this menu category?")) {
      props.delete(props.category.uuid);
    }
  };

  const onNameChange = (e) => {
    props.update({
      uuid: props.category.uuid,
      name: e.target.value,
    });
  };

  const onTogglePrivacy = (e) => {
    props.update({
      uuid: props.category.uuid,
      visible: !props.category.visible,
    });
  };

  const renderLower = () => {
    const menu = props.menu;
    let items = props.category.items.filter(i => !i._destroy);

    return <div className="lower">
      {
        items.map(
          (i) => {
            return <Item
              branches={props.branches}
              key={i.uuid}
              item={i}
              menu={menu}
              saveMenu={props.saveMenu}
              restoreMenu={props.restoreMenu}
              restoreMenuItem={props.restoreMenuItem}
              update={props.updateItem}
              delete={props.deleteItem}
            />
          },
        )
      }
    </div>;
  };

  const destroyed = !!props.category._destroy;
  if (destroyed) {
    return <></>;
  }
  return (
    <div className="Category">
      {renderUpper()}
      {renderLower()}
    </div>
  );
};

const mapStateToProps = state => (
  {}
);

const mapDispatchToProps = dispatch => (
  {
    reorder: args => dispatch(reorderMenuCategory(args)),
    update: args => dispatch(updateMenuCategory(args)),
    delete: uuid => dispatch(deleteMenuCategory({uuid})),
    addItem: uuid => dispatch(createMenuItem({uuid})),
    updateItem: args => dispatch(updateMenuItem(args)),
    deleteItem: uuid => dispatch(deleteMenuItem({uuid})),
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(Category);