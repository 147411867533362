import React, { useState } from "react";
import uuidv4 from "uuid/v4";
import { Toast, ToastContainer } from "react-bootstrap";

export function useToasts() {
  let [toasts, setToasts] = useState([]);

  let addToast = (title, message) => {
    const id = uuidv4();
    setToasts([...toasts, {id, title, message}]);
  }

  let removeToast = (id) => {
    setToasts(toasts.filter(t => t.id !== id));
  }

  let renderToasts = () => {
    if (toasts.length <= 0) {
      return <></>;
    }
    return <ToastContainer position={'top-center'} containerPosition={'absolute'} className={'mt-3'}>
      {
        toasts.map((t) => <Toast show={true} itemType={'alert'} key={t.id} className={"bg-warning overflow-hidden"} onClose={() => removeToast(t.id)}>
            <Toast.Header className={'d-flex justify-content-between'}>{t.title}</Toast.Header>
            <Toast.Body className={'bg-white'}>
              {t.message.split('\n').map((line, i) => <p key={i}>{line}</p>)}
            </Toast.Body>
          </Toast>,
        )
      }
    </ToastContainer>
  }


  return {toasts, addToast, removeToast, renderToasts};
}
