import React from 'react';
import { axios } from "../../../../utilities/axios";
import './Header.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const Header = (props) => {
  const nameInputRef = React.useRef(null);
  const {menu, user, addToast} = props;

  React.useEffect(() => {
    if (menu && !menu.name) {
      nameInputRef.current.focus();
    }
  }, [menu]);

  const renderSave = () => {
    return <div className="save">
      <p>Changes have been made to this menu. <br/>Press 'Publish Changes' for them to take effect.</p>
      <button className="btn btn-sm red" onClick={props.save}>Publish Changes</button>
    </div>;
  };

  const renderCreate = () => {
    return <div className="save">
      <p>This is a new menu, press 'Create Menu' to save it.<br/>The menu will remain private until changed.</p>
      <button className="btn btn-sm red" onClick={props.save}>Create Menu</button>
    </div>;
  };

  const renderTogglePrivacy = () => {
    const {visible} = menu;

    return <div className="private btn-group btn-group-sm">
      <button
        onClick={togglePrivate}
        className={`btn ${visible ? 'btn-outline-secondary' : 'btn-primary'}`}
      >
        Private
      </button>

      <button
        onClick={togglePrivate}
        className={`btn ${visible ? 'btn-primary' : 'btn-outline-secondary'}`}
      >
        Public
      </button>
    </div>;
  };

  const togglePrivate = () => {
    const {visible, uuid} = menu;
    const {email, auth_token} = user;
    const auth = {username: email, password: auth_token};

    let menuUpdate = {uuid, visible: !visible};
    axios.patch('/api/business/v1/menus/' + uuid, {menu: menuUpdate}, {auth})
      .then(_res => props.updateMenu(menuUpdate))
      .catch(err => {
        let errors = err.response.data.errors;
        addToast("An error occurred updating the menu's visibility", errors.join('\n'));
      });
  };

  const updateName = (e) => {
    const {uuid} = menu;
    props.updateMenu({uuid, name: e.target.value});
  };

  const addCategory = () => {
    const {uuid} = menu;
    props.addCategory({uuid});
  };

  const deleteMenu = () => {
    const {uuid} = menu;
    if (window.confirm("Are you sure you want to delete this entire menu (and its contents)?")) {
      props.deleteMenu({uuid});
    }
  };

  let right;
  if (menu.new) {
    right = renderCreate();
  } else if (menu.edited) {
    right = renderSave();
  } else {
    right = renderTogglePrivacy();
  }

  return <div className="Header align-items-center justify-content-between">
    <div className="d-flex align-items-center align-content-center gap-3">
      <span className="btn btn-light d-flex d-lg-none align-items-center align-content-center" onClick={props.toggleSidebar}>
        <FontAwesomeIcon icon={faBars} size={"1x"}/>
      </span>

      <input
        type="text"
        value={menu.name || ""}
        placeholder="Menu Name"
        onChange={updateName}
        ref={nameInputRef}
      />
    </div>

    <div className="d-flex flex-wrap align-items-center align-content-center gap-3">
      <button className="btn btn-sm btn-primary" onClick={addCategory}>Add Section</button>

      <button className="btn btn-sm btn-outline-danger px-4" onClick={deleteMenu}>
        <FontAwesomeIcon icon={faTrashAlt}/>
      </button>
      {right}
    </div>
  </div>;
};

export default Header;