import React from 'react';
import { axios } from "../../utilities/axios";
import { Link } from 'react-router-dom';
import AuthScene from './AuthScene/AuthScene';

const NewPasswordScene = () => {
  const [loading, setLoading] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(false);

  const resetToken = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('reset_password_token');
  };

  const onChange = (e) => {
    const {name, value} = e.target;
    if (name === 'password') {
      setPassword(value);
    } else if (name === 'password_confirmation') {
      setPasswordConfirmation(value);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    setError(false);
    setSuccess(false);
    setLoading(true);

    const prefix = "";
    const token = resetToken();

    axios.post(
      `${prefix}/api/business/v1/authentication/new-password`,
      {
        password_reset_token: token,
        password,
        password_confirmation: passwordConfirmation,
      },
    )
      .then(() => {
        setLoading(false);
        setSuccess(true);
      })
      .catch(err => {
        setLoading(false);
        setError(err.response.data['error'] || 'Something went wrong');
      });
  };

  const token = resetToken();

  return (
    <AuthScene title={"New Password"}>
      {token == null ? (
        <p className="error">Token invalid. Please click a valid link from the password reset email you were sent</p>
      ) : success ? (
        <React.Fragment>
          <p className="success">Password successfully reset. Please login with your new password</p>
          <Link to="/" className={"button"}>Back to Login</Link>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>
            Please enter your new password below.
          </p>

          {error ? <p className="error">{error}</p> : null}

          <form onSubmit={onSubmit} className={loading ? 'loading' : ''}>
            <input
              name="password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={onChange}
            />
            <input
              name="password_confirmation"
              type="password"
              placeholder="Password Confirmation"
              value={passwordConfirmation}
              onChange={onChange}
            />
            <button className={loading ? 'loading' : ''} onClick={onSubmit}>
              {loading ? 'Loading...' : 'Set Password'}
            </button>
          </form>
        </React.Fragment>
      )}
    </AuthScene>
  );
};

export default NewPasswordScene;
  
