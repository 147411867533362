import React, { useState } from 'react';
import { axios } from "../../utilities/axios";
import { connect } from 'react-redux';
import { cacheMenu, createMenuCategory, deleteMenu, restoreMenu, restoreMenuItem, updateMenu, updateMenuCategory, validateMenu } from '../../store/menus';
import './MenuScene.scss';
import PageLayout from '../../components/PageLayout';
import Header from './components/Header';
import Category from './components/Category';
import { useToasts } from "../../components/Toasts";

const MenuScene = (props) => {
    const {menu, user} = props;
    const [sidebarOpen, setSidebarOpen] = useState(false);
    let {addToast, renderToasts} = useToasts();

    const deleteMenu = () => {
      const auth = {username: user.email, password: user.auth_token};
      axios.delete(`/api/business/v1/menus/${menu.uuid}`, {auth})
        .then(res => props.deleteMenu({uuid: menu.uuid}))
        .catch(err => {
          let errors = err.response.data.errors;
          addToast("An error occurred deleting this menu", errors.join('\n'));
        });
    };

    const onRestore = () => {
      if (window.confirm("Are you sure you want to cancel without saving your changes?")) {
        props.restoreMenu(menu);
        return true;
      }
      return false;
    };

    const onRestoreItem = (args) => {
      if (window.confirm("Are you sure you want to cancel without saving your changes?")) {
        props.restoreMenuItem(args);
        return true;
      }
      return false;
    };

    const onSave = () => {
      const errors = validateMenu(menu);
      if (errors.length > 0) {
        addToast("Error", `Please correct invalid data (${errors.length} Errors): \n\n${errors.join('\n')}`);
        return false;
      }

      const params = {
        uuid: menu.uuid,
        name: menu.name,
        description: menu.description,
        visible: menu.visible,
        categories_attributes: menu.categories.map(c => (
          {
            id: c.id,
            _destroy: c._destroy,
            uuid: c.uuid,
            name: c.name,
            visible: c.visible,
            description: c.description,
            sorting_order: c.sorting_order,
            items_attributes: c.items,
          }
        )),
      };

      const url = menu.new ? '/api/business/v1/menus/' : `/api/business/v1/menus/${menu.uuid}`;
      const auth = {username: user.email, password: user.auth_token};
      let success = (res) => {
        let updatedMenu = {
          edited: false,
          ...res.data,
        };

        props.updateMenu(updatedMenu);
        props.cacheMenu(updatedMenu);
        return true;
      }

      let failure = (err) => {
        let response = err.response;
        let errors = response.data.errors;
        addToast("An error occurred updating the menu", errors.join('\n'));
      }

      if (menu.new) {
        axios.post(url, {menu: params}, {auth}).then(success).catch(failure);
      } else {
        axios.patch(url, {menu: params}, {auth}).then(success).catch(failure);
      }
      return true;
    };

    if (!menu) {
      return <PageLayout title="Error: Menu Not Found" {...props} />;
    }

    const categories = [...menu.categories].sort((a, b) => {
      let x = a.sorting_order;
      let y = b.sorting_order;
      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      } else {
        return 0;
      }
    });

    return (
      <PageLayout title="MenuScene" {...props} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
        {renderToasts()}

        <Header
          key={menu.uuid}
          menu={menu}
          user={user}
          sidebarOpen={sidebarOpen}
          toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
          updateMenu={props.updateMenu}
          addCategory={props.createMenuCategory}
          deleteMenu={deleteMenu}
          addToast={{addToast}}
          save={onSave}
        />

        <div className="categories-container">
          {categories.map((c) => {
            return <Category
              branches={user.business.branches}
              category={c}
              key={c.uuid}
              menu={menu}
              restoreMenu={onRestore}
              restoreMenuItem={onRestoreItem}
              saveMenu={onSave}
              updateMenuCategory={props.updateMenuCategory}
              user={user}
              visible={c.visible}
            />;
          })}
        </div>
      </PageLayout>
    )
      ;
  }
;

const mapStateToProps = (state, props) => (
  {
    menu: state.menus[props.match.params.uuid],
    user: state.auth.user,
  }
);

const mapDispatchToProps = dispatch => (
  {
    restoreMenu: args => dispatch(restoreMenu(args)),
    cacheMenu: args => dispatch(cacheMenu(args)),
    restoreMenuItem: args => dispatch(restoreMenuItem(args)),
    updateMenu: args => dispatch(updateMenu(args)),
    createMenuCategory: uuid => dispatch(createMenuCategory(uuid)),
    updateMenuCategory: uuid => dispatch(updateMenuCategory(uuid)),
    deleteMenu: uuid => dispatch(deleteMenu(uuid)),
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(MenuScene);