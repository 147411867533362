import React from 'react';
import { connect } from 'react-redux';
import PageLayout from './components/PageLayout';
import { Login, NewPassword, ResetPassword } from './scenes/Auth';
import MenuScene from './scenes/Menu';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Rollbar from './components/Rollbar';

function App({loggedIn}) {
  const unauthenticatedRoutes = () => {
    return <div className="App" style={{height: '100%'}}>
      <Switch>
        <Route exact path="/" component={Login}/>
        <Route exact path="/forgot-password" component={ResetPassword}/>
        <Route path="/new-password" component={NewPassword}/>
        <Route path="*"><Redirect to="/"/></Route>
      </Switch>
    </div>;
  };

  const authenticatedRoutes = () => {
    return <div className="App" style={{height: '100%'}}>
      <Switch>
        <Route exact path="/">
          <PageLayout sidebarOpen={true}/>
        </Route>
        <Route exact path="/logout" component={PageLayout}/>
        <Route exact path="/menus/:uuid" component={MenuScene}/>
        <Route path="/new-password"><Redirect to="/"/></Route>
      </Switch>
    </div>;
  };

  return (
    <Rollbar>
      <Router>
        {loggedIn ? authenticatedRoutes() : unauthenticatedRoutes()}
      </Router>
    </Rollbar>
  );
}

const mapStateToProps = store => (
  {
    loggedIn: !!store.auth.user,
  }
);

export default connect(mapStateToProps)(App);

