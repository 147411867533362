import React from 'react';
import { axios } from "../../utilities/axios";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthScene from "./AuthScene/AuthScene";

const LoginScene = ({loadingFromCookies, setUser}) => {
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState(null);

  // Allow time for cookie credentials to be validated before showing UI
  if (loadingFromCookies) {
    return <div className="LoginScene"/>;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);

    // const prefix = "http://localhost:5000"
    const prefix = "";

    axios.post(`${prefix}/api/business/v1/authentication/login`, {email, password})
      .then(res => setUser(res.data))
      .catch(err => {
        setLoading(false);
        setError('Incorrect Email / Password');
      });
  };

  const onChange = (e) => {
    const {name, value} = e.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  return (
    <AuthScene>
      {error ? <p className="error">{error}</p> : null}

      <form onSubmit={onSubmit} className={loading ? 'loading' : ''}>
        <input
          name="email"
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={onChange}
        />
        <input
          name="password"
          type="password"
          placeholder="Password"
          value={password}
          onChange={onChange}
        />
        <button
          className={loading ? 'loading' : ''}
          onClick={onSubmit}
        >
          {loading ? 'Loading...' : 'Login'}
        </button>
        <br/>
        <Link to="/forgot-password">Forgotten your password?</Link>
      </form>
    </AuthScene>
  );
};

const mapStateToProps = store => {
  return {
    loadingFromCookies: store.auth.loadingFromCookies,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUser: payload => dispatch({type: 'AUTH_SET_USER', payload}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScene);
