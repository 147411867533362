import React from 'react';
import Logo from "../../../assets/images/logo.png";
import './AuthScene.scss';
import EnvRibbon from "../../../components/StagingRibbon";
import { Link } from "react-router-dom";

const AuthScene = (props) => {
  return (
    <div className="AuthScene">
      <div className="inner">
        <Link to="/">
          <img src={Logo} alt="Logo"/>
        </Link>
        <EnvRibbon/>
        <h1>
          Business Login
          {props.title && <React.Fragment>{" - " + props.title}</React.Fragment>}
        </h1>
        {props.children}
      </div>
    </div>
  );
};

export default AuthScene;